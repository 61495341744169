import request from '@/utils/request'


// 查询用户编辑信息申请列表
export function listEditApply(query) {
  return request({
    url: '/user/user-edit-applys/list',
    method: 'get',
    params: query
  })
}

// 查询用户编辑信息申请分页
export function pageEditApply(query) {
  return request({
    url: '/user/user-edit-applys/page',
    method: 'get',
    params: query
  })
}

// 查询用户编辑信息申请详细
export function getEditApply(data) {
  return request({
    url: '/user/user-edit-applys/detail',
    method: 'get',
    params: data
  })
}

// 新增用户编辑信息申请
export function addEditApply(data) {
  return request({
    url: '/user/user-edit-applys/add',
    method: 'post',
    data: data
  })
}

// 修改用户编辑信息申请
export function updateEditApply(data) {
  return request({
    url: '/user/user-edit-applys/edit',
    method: 'post',
    data: data
  })
}

// 删除用户编辑信息申请
export function delEditApply(data) {
  return request({
    url: '/user/user-edit-applys/delete',
    method: 'post',
    data: data
  })
}
export function approveEditInfo(data) {
  return request({
    url: '/user/user-edit-applys/approveEditInfo',
    method: 'post',
    data: data
  })
}